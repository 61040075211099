
















import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  ListingStatus, Office, Profile, WebsiteLevel, MapSettings, WebsiteProfileDisplayType, ListingCategory, MethodOfSale,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';

import Listings from '@/components/Listings.vue';
import Loader from '@/components/UI/Loader.vue';
import OfficeListingSearch from '@/components/OfficeListingSearch.vue';

@Component({
  components: {
    Listings,
    Loader,
    OfficeListingSearch,
  },
})
export default class ListingSearch extends Mixins(AsyncView) {
  private office: Office | null = null;

  private listingFilter: ListingFilter | null = null;

  get getMos(): string {
    return this.$route.fullPath.replace('/listings/', '');
  }

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  public head(): RouteMeta {
    return {
      title: this.getMos,
      description: 'Explore Properties with Explore Property Townsville City',
      // image: this.listing!.Images.length ? this.listing!.Images[0].Preview.Url : undefined,
    };
  }

  gotFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
